<template>
	<b-card class="pb-2" no-body>
		<div class="m-2">
			<!-- Table Top -->
			<b-row>
				<!-- Search
				<b-col cols="12" md="2">
					<div class=" mb-1 mb-md-0">
						<b-form-input
							v-model="searchQuery"
							class="d-inline-block mr-1"
							:placeholder="$t('Please Enter...')"
							style="width:100%"
						/>
					</div>
				</b-col>
				<b-col cols="12" md="4">
					<div class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
						<v-select
							class="mr-1"
							v-model="statusFilter"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="statusOptions"
							:placeholder="$t('Please Select')"
						>
						</v-select>
						<b-button
							variant="primary"
							@click="searchAction"
						>
							<span class="text-nowrap">{{$t('Search')}}</span>
						</b-button>
					</div>
				</b-col> -->
				<b-col cols="12" md="12"
					class="d-flex align-items-center justify-content-start"
				>
					<b-button
						variant="primary"
						@click="isDeviceNewSidebarActive=true"
					>
						<span class="text-nowrap">{{$t('Add Device')}}</span>
					</b-button>
				</b-col>
			</b-row>
		</div>

		<b-table
			ref="deviceList"
			class="position-relative"
			stacked="sm"
			primary-key="id"
			hover
			show-empty
			:empty-text="$t('No Data')"
			:fields="tableColumns"
			:items="dataList"
			:busy="loading"
		>
			<!-- Column: online -->
			<template #cell(online)="data">
				<b-badge variant="success" v-if="data.item.online==1">{{ $t('Online') }}</b-badge>
				<b-badge variant="danger" v-else>{{ $t('Offline') }}</b-badge>
			</template>
			<!-- Column: Actions -->
			<template #cell(actions)="data">
				<!-- 详情 -->
				<feather-icon
					:id="`${data.item.id}-info-icon`"
					icon="InfoIcon"
					class="cursor-pointer"
					size="16"
					@click="$router.push({ name: 'deviceDetail', query: { id: data.item.id, mac: data.item.mac }})"
				/>
				<b-tooltip
					:title="$t('Details')"
					class="cursor-pointer"
					:target="`${data.item.id}-info-icon`"
				/>
				<!-- 开关机日程 -->
				<feather-icon
					:id="`${data.item.id}-calendar-icon`"
					icon="CalendarIcon"
					class="cursor-pointer ml-1"
					size="16"
					@click="$router.push({ name: 'switchSchedule', query: { id: data.item.id }})"
					
				/>
				<b-tooltip
					:title="$t('Switching Schedule')"
					class="cursor-pointer"
					:target="`${data.item.id}-calendar-icon`"
				/>
				<!-- 节目单 -->
				<feather-icon
					:id="`${data.item.id}-program-schedule`"
					icon="ClipboardIcon"
					class="cursor-pointer ml-1"
					size="16"
					@click="$router.push({ name: 'programSchedule', query: { id: data.item.id }})"
					
				/>
				<b-tooltip
					:title="$t('Program Schedule')"
					class="cursor-pointer"
					:target="`${data.item.id}-program-schedule`"
				/>
				<!-- 广告橱窗 -->
				<!-- <feather-icon
					:id="`${data.item.id}-ad-icon`"
					icon="CodepenIcon"
					class="cursor-pointer ml-1"
					size="16"
					@click="$router.push({ name: 'deviceAd', query: { id: data.item.id }})"
					
				/>
				<b-tooltip
					:title="$t('Advertising Window')"
					class="cursor-pointer"
					:target="`${data.item.id}-ad-icon`"
				/> -->
				<!-- <b-dropdown
					variant="link"
					no-caret
					:right="$store.state.appConfig.isRTL"
				>
					<template #button-content>
						<feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
					</template>
					<b-dropdown-item :to="{ name: 'programSchedule', params: { id: data.item.id } }">
						<feather-icon icon="CalendarIcon" />
						<span class="align-middle ml-50">{{$t('Program Schedule')}}</span>
					</b-dropdown-item>
					<b-dropdown-item @click="deleteAction">
						<feather-icon icon="Trash2Icon" />
						<span class="align-middle ml-50">{{$t('Delete')}}</span>
					</b-dropdown-item>
				</b-dropdown> -->
			</template>

		</b-table>
		
		<!-- Pagination -->
		<Pagination
			:currentPage="currentPage"
			:pageSize="pageSize"
			:count="dataList.length"
			class="mt-2"
			v-if="dataList.length>0"
		></Pagination>
		<!-- new device -->
		<!-- <b-sidebar
			id="sidebar-new"
			bg-variant="white"
			right
			backdrop
			shadow
		> 
			<sidebar-content />
		</b-sidebar> -->
		<!-- new  -->
		<device-new
			:is-active.sync="isDeviceNewSidebarActive"
			@refetch-data="getData"
		/>
		<Function>
			<ul class="colors-list list-unstyled mb-0">
				<li>
					<feather-icon size="15" icon="ChevronsRightIcon" />
					<span>{{$t('Function Declaration')}}1</span>
				</li>
				<li>
					<feather-icon size="15" icon="ChevronsRightIcon" />
					<span>{{$t('Function Declaration')}}2</span>
				</li>
			</ul>
		</Function>
	</b-card>
</template>

<script>
import {
	BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
	BBadge, BDropdown, BDropdownItem, BTooltip,BSidebar,VBToggle, BListGroup, BListGroupItem
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import DeviceNew from '@/components/device/New.vue'
import Pagination from "@/components/public/Pagination.vue";
import { getDevice } from "@/libs/apis/serverApi";

export default {
	components: {
		BCard,
		BRow,
		BCol,
		BFormInput,
		BButton,
		BTable,
		BLink,
		BBadge, 
		BDropdown, 
		BDropdownItem, 
		BTooltip,
		BSidebar,
		BListGroup, BListGroupItem,
		vSelect,
		DeviceNew,

		Pagination
	},
	directives: {
		'b-toggle': VBToggle,
	},
	data () {
		return  {
			searchQuery: '',
			statusFilter: '',
			statusOptions: [
				{ label: 'Pending', value: 'pending' },
				{ label: 'Active', value: 'active' },
				{ label: 'Inactive', value: 'inactive' },
			],
			sortBy: 'id',
			isSortDirDesc: true,
			tableColumns: [
				{ key: 'id', },
				{ key: 'mac', },
				{
					key: 'online',
					label: this.$t('Online Status'),
					// formatter: (value) => {return value==1 ?  $t('Sold Out') :  $t('Putaway')},
					sortable: false,
				},
				{ key: 'created_at',label: this.$t('Bind Time'), },
				{ key: 'actions',label: this.$t('Actions'), },
			],
			loading: false,
			dataList: [],
			pageSize: 10,
			currentPage: 1,
			activeData: null,
			isDeviceNewSidebarActive: false
		}
	},
	created () {
		this.getData()
	},
	methods: {
		getData(page) {
			this.loading = true
			page = page || 1;
			getDevice({
				offset: (page - 1) * this.pageSize,
				limit: this.pageSize,
			}).then((res) => {
				this.loading = false
				this.dataList = res.rows;
				this.currentPage = page;
			}, err=>{
				this.loading = false
				this.$toast.error(err.message)
			});
		},
		searchAction () {},

		deleteAction(item){
			this.$bvModal
				.msgBoxConfirm(this.$t('deleteDataTip'), {
					title: this.$t('Tips'),//'Please Confirm',
					size: 'sm',
					okVariant: 'danger',
					okTitle: this.$t('Delete'),
					cancelTitle: this.$t('Cancel'),
					cancelVariant: 'outline-secondary',
					hideHeaderClose: false,
					centered: true,
				})
				.then(value => {
					// 
				})
		}
	}
}
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
